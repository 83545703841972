import axios from 'axios'
import { ADMIN_API_URL } from './constants'

const excludedRoutes = [
  `${ADMIN_API_URL}/token/`
]

export default {
  setupInterceptors (store) {
    // Request
    axios.interceptors.request.use(async (config) => {
      const auth = JSON.parse(sessionStorage.getItem('auth'))
      if (excludedRoutes.indexOf(config.url) === -1 && auth) {
        config.headers.Authorization = `Token ${auth.token}`
      }
      return config
    }, (err) => {
      return Promise.reject(err)
    })

    // Response
    axios.interceptors.response.use((response) => {
      return response
    }, (error) => {
      if (error.response.status === 401) {
        sessionStorage.clear()
      } else {
        return Promise.reject(error)
      }
    })
  }
}
